<button 
  class="btn btn-circle tooltip tooltip-left"
  [ngClass]="{'btn-xs': connected(), 'btn-error': error(), 'btn-accent': disconnected(), 'btn-sm': small()}" 
  (click)="toggleState()"
  appBackgroundMagnitude 
  [magnitude]="vol()"
  [attr.data-tip]="connected() ? ('LABELS.stop' | translate) : ('LABELS.listen' | translate)"
  >
  <ng-icon *ngIf="error(); else micEnabled" class="tooltip tooltip-warning tooltip-left" [attr.data-tip]="error()" name="heroExclamationTriangle"></ng-icon>
  <ng-template #micEnabled>
    <ng-container *ngIf="connected(); else inactive">
      <ng-icon name="heroMicrophone"></ng-icon>
    </ng-container>
    <ng-template #inactive>
      <ng-icon name="heroMicrophoneSlash"></ng-icon>
    </ng-template>
  </ng-template>
</button>