<div class="flex flex-col items-center justify-center p-6 gap-12 bg-primary basis-full">
  <p class="flex max-w-2xl text-4xl sm:text-6xl leading-normal text-center">
    {{ 'WELCOME.p1' | translate }}
  </p>
  <p class="flex max-w-2xl text-base leading-relaxed text-center">
    {{ 'WELCOME.p2' | translate }}
  </p>

  <app-supporter-render class="flex max-w-2xl text-base leading-relaxed text-center"></app-supporter-render>
  
  <button *ngIf="!isLoggedIn()" class="btn btn-secondary text btn-wide btn-xl rounded-full" [routerLink]="['auth', 'login']">
    <span translate>BUTTONS.login</span>
  </button>
  
</div>
