<div class="flex flex-col justify-end basis-full flex-grow-0 flex-shrink-0 bg-base-100 text-base-content p-6 gap-3 overflow-hidden relative">
  <div *ngIf="error() as err" class="text-error self-stretch text-center text-md sm:text-xl">{{'ERRORS.RECOGNITION.' + err | translate}}</div>
  <div *ngIf="!hasLiveResults()" @fadeOutOnLeave class="text-center h-auto fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
    <div class="text-sm text-accent flex flex-row text-center" translate>{{hintText}}</div>
  </div>
  
  <div *ngFor="let text of renderedResults()" @fadeInOnEnter @fadeOutOnLeave [classList]="classList()">
    {{text | proper}}
  </div>
  <div class="absolute top-0 left-0 right-0 bottom-0 flex flex-col gap-3 items-center justify-center" *ngIf="isPaused()">
    <div class="badge badge-warning px-6 py-3 text-center h-auto" translate>HINTS.recognitionPaused</div>
    <button class="btn btn-primary rounded-box" *ngIf="recognitionPaused()" (click)="resume()" translate>HINTS.resume</button>
  </div>
</div>