<div class="flex flex-col items-center justify-center p-6 gap-12 text-md">
  <div *ngIf="loggedOut()">
    <span translate>SUPPORTER.notLoggedIn</span>
  </div>
  <div *ngIf="activeSupporter()">
    <span translate>SUPPORTER.active</span>
  </div>
  <div *ngIf="following()">
    <span translate>SUPPORTER.following</span>
  </div>
  <div *ngIf="notFollowing()">
    <span translate>SUPPORTER.notFollowing</span>
  </div>
  <a [href]="patreonUrl" target="_blank" class="btn btn-secondary btn-rounded px-6 py-1">
    <ng-icon name="patreonLogo" size="24"></ng-icon>
    <span translate>SUPPORTER.visit</span>
  </a>
</div>
